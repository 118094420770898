<template>
  <label-modal-layout
    :labels="labels"
    :order-name="orderName"
    :return-by="returnBy"
    :content="content"
    :carrier="carrier"
  >
    <template
      v-if="carrier"
      #label-card-header
    >
      <render-content :data="{ carrier }">
        {{ content.printedLabelCardHeader }}
      </render-content>
    </template>

    <template
      v-if="carrier"
      #description
    >
      <render-content :data="{ formattedReturnByDate, carrier, locationLink }">
        {{ content.printedLabelDropOffInstruction }}
      </render-content>
    </template>

    <template #label-card="{ count, ...currentLabel }">
      <label-card
        :label="currentLabel"
        :count="count"
        :return-id="returnId"
        :shop-id="shopId"
        :content="content"
      >
        <base-button
          class="print-label__button"
          download="return_label.pdf"
          target="_blank"
          rel="noopener noreferrer"
          :to="url(currentLabel)"
          @click="print"
        >
          <div class="label-card__button-content">
            <base-icon name="printer" />
            <base-text class="label-card__button-copy">
              <render-content :data="{ formattedReturnByDate, carrier }">
                {{ content.printedLabelButtonCta }}
              </render-content>
            </base-text>
          </div>
        </base-button>
      </label-card>
    </template>
  </label-modal-layout>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { BaseButton, BaseIcon } from '@loophq/design-system';
import LabelModalLayout from './LabelModalLayout.vue';
import LabelCard from './LabelCard.vue';
import QrCode from '@/js/controllers/qrCode';
import Returns from '@/js/controllers/return';
import { logError } from '@/js/helpers/errors';
import formatDate from '@/js/helpers/formatDate';
import { trackEvent } from '@/js/helpers/trackEvent';
import { useLocationLinkMapper } from '@/js/composables/views/StatusPage/locationLinkMapper';

const props = defineProps({
  labels: Array,
  returnId: Number,
  shopId: Number,
  returnKey: String,
  returnBy: String,
  orderName: String,
  content: Object,
  packingSlipsEnabled: Boolean,
  hasCombinedLabelsWithPackingSlipsFlag: Boolean,
});

onMounted(() => {
  props.labels.forEach(async (label) => {
    if (label && !label?.qrCodeUrl) {
      const res = await QrCode.getLabelQrCode(
        props.shopId,
        props.returnId,
        label?.id,
      );
      try {
        if (res.data.qr_code === null) {
          throw new Error('QR code url is null');
        }
      } catch (error) {
        logError(error);
      }
      label.value = res.data.qr_code;
    }
  });
});

const formattedReturnByDate = computed(() => {
  return formatDate(props.returnBy);
});

const carrier = computed(() => {
  if (props.labels.length > 0) {
    return props.labels[0]?.carrier;
  }

  return null;
});

const locationLink = computed(() => {
  return useLocationLinkMapper(carrier);
});

function print() {
  Returns.updatePrintLabelCount(props.returnKey);

  trackEvent('print label clicked');

  if (props.packingSlipsEnabled && props.hasCombinedLabelsWithPackingSlipsFlag) {
    trackEvent('generate packing slip clicked');
  }
}

function url(label) {
  return (props.packingSlipsEnabled && props.hasCombinedLabelsWithPackingSlipsFlag)
    ? label?.labelPackingSlip
    : label?.labelUrl ?? null;
}
</script>

<style lang="scss" scoped>
:deep(.print-label__button) {
  width: 100%;
  margin-bottom: var(--spacing-300);
}
</style>
